/* eslint-disable max-classes-per-file */

'use client';

import Button from '@components/Button';
import { Checkbox, Input } from '@components/Form';
import useSWRMutation from 'swr/mutation';
import { useId } from 'react';
import { Failure } from 'superstruct';
import Select from '@components/Form/Select';
import { SALUTATION_CHOICES, STATE_CHOICES } from '@utils/choices';

class RegistrationError extends Error {
  failures: Record<string, Failure>;

  constructor(failures: Record<string, Failure>) {
    super();
    this.name = 'RegistrationError';
    this.failures = failures;
  }
}

class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
  }
}

async function submitData(url: string, { arg }: { arg: FormData }) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify(Object.fromEntries(arg)),
    });

    if (!response.ok) {
      const error = (await response.json()) as Record<string, Failure>;
      throw new RegistrationError(error);
    }

    return 'Success';
  } catch (err) {
    if (err instanceof RegistrationError) {
      throw err;
    }
    throw new TimeoutError('Something bad happened...');
  }
}

type Props = {
  title?: string;
  description?: string | null;
  pseudoProduct: string;
  courseType: string;
  course: string;
  onSuccess?: () => void;
};

function EventRegistrationForm(props: Props) {
  const { course, courseType, title, description, onSuccess, pseudoProduct } = props;
  const id = useId();

  const { trigger, isMutating, error } = useSWRMutation('/api/register', submitData, {
    onSuccess,
    throwOnError: false,
  });

  return (
    <>
      {title ? <h3 className='event-registration-form__title'>{title}</h3> : null}
      {description ? (
        <div className='event-registration-form__description'>
          <p className='event-registration-form__description-title'>Datum</p>
          <p className='event-registration-form__description-text'>{description}</p>
        </div>
      ) : null}
      <form
        className='event-registration-form'
        onSubmit={e => {
          e.preventDefault();

          const formData = new FormData(e.currentTarget);
          formData.append('type', 'anmeldung');
          formData.append('kurstyp', courseType);
          formData.append('kurs', course);
          formData.append('pseudo_product', pseudoProduct);

          trigger(formData);
        }}
      >
        <div className='event-registration-form__row'>
          <Select
            label='Anrede'
            name='salutation'
            placeholder='Keine Anrede'
            items={SALUTATION_CHOICES.map(salutation => ({ value: salutation, text: salutation }))}
          />
        </div>
        <div className='event-registration-form__row'>
          <Input
            id={`${id}_first_name`}
            name='first_name'
            label='Vorname'
            required
            error={error?.failures?.first_name}
          />
          <Input
            id={`${id}_last_name`}
            name='last_name'
            label='Nachname'
            required
            error={error?.failures?.first_name}
          />
        </div>
        <div className='event-registration-form__row'>
          <Input
            id={`${id}_email`}
            name='email'
            type='email'
            label='E-Mail-Adresse'
            required
            error={error?.failures?.email}
          />
          <Input
            id={`${id}_phone`}
            name='phone'
            label='Telefonnummer'
            error={error?.failures?.phone}
          />
        </div>
        <div className='event-registration-form__row'>
          <Select
            label='Bundesland'
            name='state'
            placeholder='Keine Angabe'
            items={STATE_CHOICES.map(state => ({ value: state, text: state }))}
          />
        </div>
        <div className='event-registration-form__row'>
          <Input
            id={`${id}_zip`}
            name='zip'
            type='text'
            label='Postleitzahl'
            required
            error={error?.failures?.zip}
          />
          <Input
            id={`${id}_city`}
            name='city'
            label='Stadt'
            required
            error={error?.failures?.city}
          />
        </div>
        <Checkbox
          id={`${id}_data_protection`}
          name='data_protection'
          label='Hiermit stimme ich der Verarbeitung meiner Daten zu. Ich kann meine Zustimmung jederzeit mit Wirkung für die Zukunft widerrufen. Weitere Informationen finden Sie in der Datenschutzerklärung.'
          value='checked'
          error={error?.failures?.data_protection}
        />
        {error && error instanceof TimeoutError ? <p>Leider ist etwas schief gegangen...</p> : null}
        <Button type='submit' primary disabled={isMutating}>
          {isMutating ? 'Übermittle Daten...' : 'Absenden'}
        </Button>
      </form>
    </>
  );
}
export default EventRegistrationForm;
