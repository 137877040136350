'use client';

import { Label } from '@components/Form';

import LoadingSpinner from '@components/LoadingSpinner';
import RawHtml from '@components/RawHtml';
import { Kurs, Product } from '@streamfields/EventFinder/EventFinder';
import EventRegistrationForm from '@streamfields/EventFinder/EventRegistrationForm';
import dynamic from 'next/dynamic';
import { useId, useReducer } from 'react';
import useSWR from 'swr';

import Select from '@components/Form/Select';
import AngleRightIcon from '@icons/angle-right.svg';
import SearchIcon from '@icons/search.svg';
import { formatDate } from '@utils/formatter';
import { useSearchParams } from 'next/navigation';

const Dialog = dynamic(() => import('@components/Dialog'));

type State = {
  searchQuery: string | null;
  selectedProduct: string;
};

const initialState: State = {
  searchQuery: null,
  selectedProduct: '',
};

const fetcher = (url: string) => fetch(url).then(r => r.json() as Promise<Kurs[]>);

type Props = { products: Product[]; pseudoProduct: string; onSuccess?: () => void };

function OfflineCoursesWithSelection(props: Props) {
  const { products, pseudoProduct, onSuccess } = props;
  const id = useId();
  const searchParams = useSearchParams();
  const [state, dispatch] = useReducer((s: State, a: Partial<State>) => ({ ...s, ...a }), {
    ...initialState,
    selectedProduct: searchParams.get('course')?.toString() ?? '',
  });

  // fetch data if searchQuery is set
  const { data, isLoading } = useSWR<Kurs[]>(() => {
    if (!state.searchQuery || !state.selectedProduct) return false;

    return `/api/courses/?city=${state.searchQuery}&distance=${
      process.env.NEXT_PUBLIC_SEARCH_DISTANCE ?? 50
    }&ktyp=${state.selectedProduct}`;
  }, fetcher);

  const product = products.find(p => p.value.kurs === state.selectedProduct);
  return (
    <>
      <form
        className='event-finder__form'
        onSubmit={async e => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const searchTerm = formData.get('search');
          const selectedProduct = formData.get('product');

          dispatch({
            searchQuery: searchTerm?.toString(),
            selectedProduct: selectedProduct?.toString(),
          });
        }}
      >
        <Select
          label='Wähle dein Kursmodell'
          placeholder='Kurs auswählen'
          items={[
            { value: 'all', text: 'Alle Starttermine anzeigen' },
            ...products.map(p => ({ value: p.value.kurs, text: p.value.title })),
          ]}
          name='product'
          defaultValue={searchParams.get('course')?.toString() ?? ''}
          rounded
        />
        <div className='event-finder__location-search'>
          <Label htmlFor={`${id}-search`} text='Standort suchen' className='sr-only' />
          <input
            id={`${id}-search`}
            name='search'
            type='search'
            placeholder='Wohnort oder PLZ eingeben'
          />
          <button type='submit' aria-label='Suche abschicken'>
            <SearchIcon />
          </button>
        </div>
      </form>
      {isLoading ? <LoadingSpinner /> : null}
      {state.searchQuery && !isLoading && !data?.length ? (
        <p className='event-finder__text'>
          Für diese Suchanfrage sind leider keine Termine vorhanden.
        </p>
      ) : null}
      {data?.length ? (
        <div className='event-finder__events'>
          <div className='event-finder__event'>
            <h3 className='event-finder__event-title'>{product?.value.title}</h3>
            <RawHtml
              className='event-finder__event-description'
              html={product?.value.description}
            />
            {data.slice(0, product?.value?.number_dates_to_emit)?.map(c => (
              <Dialog
                // eslint-disable-next-line no-underscore-dangle
                key={c._oid}
                triggerText={
                  <>
                    <span>
                      {c.von ? <span>Kursstart: {formatDate(c.von)}</span> : null}
                      <span>Kursform: {c.vollzeitteilzeit}</span>
                      <span>Ausbildungsort: {c?.ausbildungsort?.name}</span>
                    </span>
                    <span>
                      <AngleRightIcon />
                    </span>
                  </>
                }
                triggerClassName='event-finder__event-registration-button'
                title='Bitte senden Sie mir weitere Informationen und die Anmeldung zu!'
              >
                <EventRegistrationForm
                  title={product?.value.title}
                  description={c.teilnehmerhinweise}
                  pseudoProduct={pseudoProduct}
                  courseType={data[0].oid_ktyp}
                  // eslint-disable-next-line no-underscore-dangle
                  course={c._oid}
                  onSuccess={onSuccess}
                />
              </Dialog>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default OfflineCoursesWithSelection;
