'use client';

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import RawHtml from '@components/RawHtml';
import { Kurs, Product } from '@streamfields/EventFinder/EventFinder';
import EventRegistrationForm from '@streamfields/EventFinder/EventRegistrationForm';

import Select from '@components/Form/Select';
import LoadingSpinner from '@components/LoadingSpinner';
import AngleRightIcon from '@icons/angle-right.svg';
import { formatDate } from '@utils/formatter';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import useSWR from 'swr';

const Dialog = dynamic(() => import('@components/Dialog'));

const fetcher = (url: string) => fetch(url).then(r => r.json() as Promise<Kurs[]>);

type Props = { products: Product[]; pseudoProduct: string; onSuccess?: () => void };

function OnlineCoursesWithSelection(props: Props) {
  const { products, pseudoProduct, onSuccess } = props;
  const searchParams = useSearchParams();
  const [selectedProduct, setSelectedProduct] = useState(
    searchParams.get('course')?.toString() ?? '',
  );

  const { data, isLoading } = useSWR<Kurs[]>(() => {
    if (!selectedProduct) return false;
    if (selectedProduct === 'all')
      return `/api/courses/?${products.map(p => `ktyp=${p.value.kurs}`).join('&')}`;
    return `/api/courses/?ktyp=${selectedProduct}`;
  }, fetcher);

  const events = data?.reduce<Record<string, Kurs[]>>((prev, curr) => {
    if (!prev[curr.oid_ktyp]) {
      prev[curr.oid_ktyp] = [];
    }
    prev[curr.oid_ktyp].push(curr);
    return prev;
  }, {});

  return (
    <>
      <Select
        label=''
        placeholder='Kursmodell auswählen'
        items={[
          { value: 'all', text: 'Alle Starttermine anzeigen' },
          ...products.map(p => ({ value: p.value.kurs, text: p.value.title })),
        ]}
        name='product'
        value={selectedProduct}
        onChange={setSelectedProduct}
        rounded
      />
      {isLoading ? <LoadingSpinner /> : null}
      {Object.entries(events ?? {}).map(([ktyp, event]) => {
        const product = products.find(p => p.value.kurs === ktyp);

        return (
          <div key={ktyp} className='event-finder__event'>
            {selectedProduct === 'all' ? (
              <h3 className='event-finder__event-title'>{product?.value?.title}</h3>
            ) : null}
            {product?.value?.description ? (
              <RawHtml
                className='event-finder__event-description'
                html={product?.value?.description}
              />
            ) : null}
            {event
              .sort((a, b) => (a.von && b.von && a.von > b.von ? 1 : -1))
              .slice(0, product?.value.number_dates_to_emit)
              .map(e => (
                <Dialog
                  key={e._oid}
                  triggerText={
                    <>
                      {e.von ? (
                        <span>
                          <span>Nächster Kursstart</span>
                          <span>{formatDate(e.von)}</span>
                        </span>
                      ) : null}
                      <span>
                        <AngleRightIcon />
                      </span>
                    </>
                  }
                  triggerClassName='event-finder__event-registration-button'
                  title='Bitte senden Sie mir weitere Informationen und die Anmeldung zu!'
                >
                  <EventRegistrationForm
                    title={product?.value.title}
                    description={e.teilnehmerhinweise}
                    pseudoProduct={pseudoProduct}
                    courseType={event[0].oid_ktyp}
                    course={e._oid}
                    onSuccess={onSuccess}
                  />
                </Dialog>
              ))}
          </div>
        );
      })}
      {}
    </>
  );
}

export default OnlineCoursesWithSelection;
