'use client';

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import RawHtml from '@components/RawHtml';
import { Kurs, Product } from './EventFinder';
import EventRegistrationForm from './EventRegistrationForm';

import AngleRightIcon from '@icons/angle-right.svg';
import dynamic from 'next/dynamic';
import LoadingSpinner from '@components/LoadingSpinner';
import useSWR from 'swr';

const Dialog = dynamic(() => import('@components/Dialog'));

const fetcher = (url: string) => fetch(url).then(r => r.json() as Promise<Kurs[]>);

type Props = { products: Product[]; pseudoProduct: string; onSuccess?: () => void };

function OnlineCourses(props: Props) {
  const { products, pseudoProduct, onSuccess } = props;

  const { data, isLoading } = useSWR<Kurs[]>(
    () => `/api/courses/?ktyp=${products.map(product => product.value.kurs).join('&ktyp=')}`,
    fetcher,
  );

  const events = data?.reduce<Record<string, Kurs[]>>((prev, curr) => {
    if (!prev[curr.oid_ktyp]) {
      prev[curr.oid_ktyp] = [];
    }
    prev[curr.oid_ktyp].push(curr);
    return prev;
  }, {});

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      {Object.entries(events ?? {}).map(([ktyp, event]) => {
        const product = products.find(p => p.value.kurs === ktyp);

        return (
          <div key={ktyp} className='event-finder__event'>
            <h3 className='event-finder__event-title'>{product?.value?.title}</h3>
            {product?.value?.description ? (
              <RawHtml
                className='event-finder__event-description'
                html={product?.value?.description}
              />
            ) : null}
            {event.slice(0, product?.value.number_dates_to_emit).map(e => (
              <Dialog
                key={e._oid}
                triggerText={
                  <>
                    <span>
                      <span>Nächster Kursstart</span>
                      <span>
                        {e.teilnehmerhinweise?.replace('Nächster Kursstart: ', '') ?? e.von}
                      </span>
                      <span>
                        {e.ausbildungsort?.name ?? e.ausbildungsort?.ort ?? 'keine Angabe'}
                      </span>
                    </span>
                    <span>
                      <AngleRightIcon />
                    </span>
                  </>
                }
                triggerClassName='event-finder__event-registration-button'
                title='Bitte senden Sie mir weitere Informationen und die Anmeldung zu!'
              >
                <EventRegistrationForm
                  title={product?.value.title}
                  description={e.teilnehmerhinweise}
                  pseudoProduct={pseudoProduct}
                  courseType={event[0].oid_ktyp}
                  course={e._oid}
                  onSuccess={onSuccess}
                />
              </Dialog>
            ))}
          </div>
        );
      })}
    </>
  );
}

export default OnlineCourses;
